import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

import { ValidationAlert } from './ValidationAlert';

export const RadioInput = ({ name, label, type, options, alert, hiddenAlert, setInputs, col, position, productId }) => {
    let classes = 'check-wrapper';
    if (alert) {
        classes += ' error';
    }

    return (
        <StyledRadioInput md={col} className="form-group" id={name + '-form-group'}>
            {label && <p>{label}</p>}
            <div className={classes}>
                {options &&
                    options.map((option) => (
                        <div key={option.value} className="form-check form-check-inline">
                            <input
                                id={name + '_' + option.name}
                                name={name}
                                type={type}
                                value={option.value}
                                className="form-check-input"
                                onChange={setInputs}
                            />
                            <label
                                htmlFor={name + '_' + option.name}
                                className={'form-check-label'}
                                dangerouslySetInnerHTML={{ __html: option.name }}
                            />
                        </div>
                    ))}
            </div>
            <ValidationAlert content={alert} />
        </StyledRadioInput>
    );
};

const StyledRadioInput = styled(Col)`
    .check-wrapper + .error {
        font-size: 12px;
        color: red;
        @media (min-width: 768px) {
            font-size: 14px;
            position: absolute;
        }
    }
    .form-check-inline {
        min-width: 140px;
        .form-check-input {
            position: absolute;
            visibility: hidden;
        }
        .form-check-label {
            position: relative;
            padding: 0 0 0 30px;
            margin: 0 0 0 -7px;
            color: #4c4c4c;
            font-size: 16px;
            font-weight: 400;
            @media (min-width: 768px) {
                font-size: 18px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 7px;
                width: 16px;
                height: 16px;
                border: 1px solid hsl(215, 100%, 69%);
                border-radius: 50%;
                transform: translateY(-9px);
                @media (min-width: 768px) {
                    transform: translateY(-8px);
                }
            }
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 10px;
                width: 10px;
                height: 10px;
                background: #5fa2ff;
                border-radius: 50%;
                box-shadow: 0 0 2px hsla(215, 100%, 69%, 0.5);
                opacity: 0;
                transform: translateY(-6px) scale(0);
                transition: opacity, transform;
                transition-duration: 0.15s;
                transition-timing-function: ease;
                @media (min-width: 768px) {
                    transform: translateY(-5px) scale(0);
                }
            }
        }
        .form-check-input:checked + .form-check-label::after {
            opacity: 1;
            transform: translateY(-6px);
            @media (min-width: 768px) {
                transform: translateY(-5px);
            }
        }
    }
`;
