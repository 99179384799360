import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

import { ValidationAlert } from './ValidationAlert';

import icoSelectArrow from '../../images/input-select-arrow.svg';

export const SelectInput = ({ ...props }) => {
    let classes = 'form-control';
    if (props.alert) {
        classes += ' error';
    }

    return (
        <StyledSelectInput md={props.col} className="form-group" id={props.name + '-form-group'}>
            {props.label && <label htmlFor={props.name}>{props.label}</label>}
            <div className="select-wrapper">
                <select
                    id={props.name}
                    name={props.name}
                    value={props.value}
                    className={classes}
                    onChange={props.setInputs}
                >
                    {props.options &&
                        props.options.map((option, key) => (
                            <option
                                key={key + '_' + option.value}
                                value={option.value}
                                disabled={option.disabled || false}
                            >
                                {option.name}
                            </option>
                        ))}
                </select>
            </div>
            <ValidationAlert content={props.alert} />
        </StyledSelectInput>
    );
};

const StyledSelectInput = styled(Col)`
    .select-wrapper {
        position: relative;
        &::before {
            content: '';
            pointer-events: none;
            position: absolute;
            top: 12px;
            right: 14px;
            background: url(${icoSelectArrow}) no-repeat center center/contain;
            width: 16px;
            height: 16px;
        }
    }
    select {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        height: auto;
        padding: 9px 32px 9px 16px;
        border: 1px solid #378aff;
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        @media (min-width: 768px) {
            font-size: 18px;
        }
        &::-ms-expand {
            // Fix for IE
            display: none;
        }
        &:focus {
            border-color: #378aff;
            box-shadow: none;
            outline: none;
            text-shadow: none;
        }
    }
`;
