export const checkIsNumber = (number) => {
    const regex = /^[0-9]+$/;
    return regex.test(number);
};

export const checkIsText = (expression) => {
    const regex = /^[-a-zA-ZÀ-ÖØ-öø-ÿàáâãéêíóôõúüçñÁÂÃÉÊÍÓÔÕÚÜÇÑ\s]+$/;
    return regex.test(expression);
};

export const checkIsTextAndNumber = (expression) => {
    const regex = /^[-a-zA-ZÀ-ÖØ-öø-ÿàáâãéêíóôõúüçñÁÂÃÉÊÍÓÔÕÚÜÇÑ\s\d]+$/;
    return regex.test(expression);
};

export const checkMinLength = (expression, length) => expression && expression.trim().length >= length;

export const checkMaxLength = (expression, length) => expression && expression.trim().length <= length;

export const checkMinValue = (expression, value) => expression && Number(expression.replace(/[^0-9]/g, '')) >= value;

export const checkIsFilled = (expression) => expression && expression.trim().length > 0;

export const checkIsFilledAmount = (expression) =>
    expression && expression.replace(/[^0-9]/g, '').length && Number(expression.replace(/[^0-9]/g, '')) !== 0;

export const checkIsTrue = (expression) => expression;

export const checkEmailPattern = (mail) => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(mail);
};

export const checkAge = (expression) => {
    const regexDate = /^(?:\d{2}\s\/\s){2}\d{4}$/;
    const regexMask = /[dmy]/g;
    let birthdayStr = String(expression);
    if (regexDate.test(birthdayStr)) {
        const dateArr = birthdayStr.replace(/\s/g, '').split('/');
        birthdayStr = dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0];
    }
    const now = new Date();
    const birthday = new Date(birthdayStr);

    if (birthdayStr.search(regexMask) >= 0) {
        return false;
    } else {
        now.setHours(0, 0, 0, 0);

        let age = now.getFullYear() - birthday.getFullYear();
        const monthsDiff = now.getMonth() - birthday.getMonth();
        const daysDiff = now.getDate() - birthday.getDate();

        if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
            age--;
        }

        if (monthsDiff > 0 || (monthsDiff === 0 && daysDiff > 0)) {
            age++;
        }

        return now > birthday && age >= 18 && age <= 80;
    }
};

export const checkNextPayDate = (expression) => {
    const regexDate = /^(?:\d{2}\s\/\s){2}\d{4}$/;
    const regexMask = /[dmy]/g;
    let paydateStr = String(expression);
    if (regexDate.test(paydateStr)) {
        const dateArr = paydateStr.replace(/\s/g, '').split('/');
        paydateStr = dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0];
    }
    const now = new Date();
    const paydate = new Date(paydateStr);

    if (paydateStr.search(regexMask) >= 0) {
        return false;
    } else {
        now.setHours(0, 0, 0, 0);

        let yearDiff = paydate.getFullYear() - now.getFullYear();
        const monthsDiff = paydate.getMonth() - now.getMonth();
        const daysDiff = paydate.getDate() - now.getDate();

        if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
            yearDiff--;
        }

        if (monthsDiff > 0 || (monthsDiff === 0 && daysDiff > 0)) {
            yearDiff++;
        }

        return now <= paydate && yearDiff <= 1;
    }
};

export const checkIsDniOrNie = (expression) => {
    const regex = /^([0-9]{8}|[XYZ][0-9]{7})[a-zA-z]$/;
    return regex.test(expression);
};

export const checkPhonePattern = (phone) => {
    const regex = /^[67][0-9]*$/;
    return regex.test(phone);
};

export const checkIsFilledBankAccount = (expression) => expression && expression.replace(/[^0-9]/g, '').length === 22;
