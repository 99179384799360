import React from 'react';
import NumberFormat from 'react-number-format';
import { Col } from 'react-bootstrap';

import { ValidationAlert } from './ValidationAlert';

export const TelInput = ({
    name,
    label,
    type,
    value,
    placeholder,
    inputMode,
    readonly,
    alert,
    hiddenAlert,
    setInputs,
    modifyInputs,
    col,
    position,
    productId,
    masked,
}) => {
    let classes = 'form-control';
    if (alert) {
        classes += ' error';
    }

    return (
        <Col md={col} className="form-group" id={name + '-form-group'}>
            {label && <label htmlFor={name}>{label}</label>}
            <NumberFormat
                id={name}
                name={name}
                type={type}
                value={value || ''}
                placeholder={placeholder || ''}
                inputMode={inputMode || 'text'}
                readOnly={readonly || false}
                className={classes}
                onChange={setInputs}
                onBlur={modifyInputs}
                isNumericString={masked.isNumericString || false}
                thousandSeparator={masked.thousandSeparator || null}
                decimalSeparator={masked.decimalSeparator || '.'} // "." is default value from NumberFormat docs
                allowEmptyFormatting={masked.allowEmptyFormatting || false}
                prefix={masked.prefix || null}
                suffix={masked.suffix || null}
                format={masked.format || null}
                mask={masked.mask || null}
                displayType={'input'}
            />
            <ValidationAlert content={alert} />
        </Col>
    );
};
