import {
    checkIsFilled,
    checkMaxLength,
    checkMinLength,
    checkIsNumber,
    checkIsText,
    checkIsTextAndNumber,
} from '../helpers/inputValidator';

export const registrationStepTwoModel = [
    {
        name: 'city',
        label: 'Localidad',
        type: 'text',
        col: 12,
        validators: [
            {
                id: 'city-text',
                isValidFun: (expression) => checkIsText(expression),
                alert: 'Este campo solo puede contener letras del alfabeto latino en mayúsculas y minúsculas',
            },
            {
                id: 'city-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'street',
        label: 'Nombre de vía',
        type: 'text',
        col: 12,
        validators: [
            {
                id: 'street-text',
                isValidFun: (expression) => checkIsText(expression),
                alert: 'Este campo solo puede contener letras del alfabeto latino en mayúsculas y minúsculas',
            },
            {
                id: 'street-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'house_number',
        label: 'Número',
        type: 'text',
        inputMode: 'decimal',
        col: 12,
        validators: [
            {
                id: 'house_number-max-length',
                isValidFun: (expression) => checkMaxLength(expression, 7),
                alert: 'Introduce el valor correcto',
            },
            {
                id: 'house_number-number',
                isValidFun: (expression) => checkIsNumber(expression),
                alert: 'Introduce el valor correcto',
            },
            {
                id: 'house_number-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'house_ext',
        label: 'Piso - Puerta',
        type: 'text',
        col: 12,
        validators: [
            {
                id: 'house_ext-max-length',
                isValidFun: (expression) => checkMaxLength(expression, 100),
                alert: 'Introduce el valor correcto',
            },
            {
                id: 'house_ext-text-and-number',
                isValidFun: (expression) => checkIsTextAndNumber(expression),
                alert: 'Este campo solo puede contener letras del alfabeto latino en mayúsculas y minúsculas',
            },
            {
                id: 'house_ext-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'zip',
        label: 'Código postal',
        type: 'text',
        maxLength: 5,
        minLength: 5,
        inputMode: 'decimal',
        col: 12,
        validators: [
            {
                id: 'zip-max-length',
                isValidFun: (expression) => checkMaxLength(expression, 5),
                alert: 'Introduce el valor correcto',
            },
            {
                id: 'house_number-min-length',
                isValidFun: (expression) => checkMinLength(expression, 5),
                alert: 'Introduce el valor correcto',
            },
            {
                id: 'house_number-number',
                isValidFun: (expression) => checkIsNumber(expression),
                alert: 'Introduce el valor correcto',
            },
            {
                id: 'zip-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'home_status',
        label: 'Tipo de vivienda',
        type: 'select',
        col: 12,
        options: [
            { value: '', name: '' },
            { value: 'HOME_OWNER', name: 'Propietario sin hipoteca' },
            { value: 'OWNER_WITH_MORTGAGE', name: 'Propietario con hipoteca' },
            { value: 'TENANT', name: 'Alquiler' },
            { value: 'OTHER', name: 'Otros' },
        ],
        validators: [
            {
                id: 'home_status',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
];
