import React from 'react';
import styled from 'styled-components';

export const Progress = ({ progress }) => {
    return (
        <StyledProgressBlock progress={progress}>
            <span className="progress-line__value">{progress}%</span>
        </StyledProgressBlock>
    );
};

const StyledProgressBlock = styled.div`
    position: absolute;
    top: 0;
    width: ${(props) => props.progress && props.progress}%;
    height: 5px;
    background: #378aff;
    .progress-line__value {
        position: absolute;
        top: 100%;
        right: 0;
        color: #378aff;
        font-size: 12px;
        font-weight: 700;
    }
`;
