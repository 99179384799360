import { normalizeDate } from '../helpers/valueNormalizer';
import {
    checkMaxLength,
    checkMinValue,
    checkIsFilled,
    checkNextPayDate,
    checkIsFilledAmount,
    checkIsFilledBankAccount,
} from '../helpers/inputValidator';

export const registrationLastStepModel = [
    {
        name: 'education',
        label: 'Nivel de estudios',
        type: 'select',
        col: 12,
        options: [
            { value: '', name: '' },
            { value: 'WITHOUT_EDUCATION', name: 'Sin estudios' },
            { value: 'PRIMARY', name: 'Secundaria obligatoria' },
            { value: 'SECONDARY_PROFESSIONAL', name: 'Formación profesional' },
            { value: 'UNIVERSITY_BACHELOR', name: 'Bachillerato' },
            { value: 'GRADUATE', name: 'Licenciado' },
            { value: 'CERTIFIED', name: 'Diplomado' },
            { value: 'OTHER', name: 'Otro' },
        ],
        validators: [
            {
                id: 'education-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'income_type',
        label: 'Situación laboral',
        type: 'select',
        col: 12,
        options: [
            { value: '', name: '' },
            { value: 'FULL_TIME_EMPLOYMENT', name: 'Empleado/a a tiempo completo' },
            { value: 'PART_TIME_EMPLOYMENT', name: 'Empleado/a a tiempo parcial' },
            { value: 'TEMPORARY_EMPLOYMENT', name: 'Empleado/a temporal' },
            { value: 'PENSION', name: 'Pensionista' },
            { value: 'RETIRED', name: 'Jubilado' },
            { value: 'INDIVIDUAL_ENTREPRENEUR', name: 'Autónomos' },
            { value: 'STUDENT', name: 'Estudiante' },
            { value: 'ARMED_FORCES', name: 'Militar' },
            { value: 'OTHER', name: 'Parado' },
            { value: 'OTHER', name: 'Otro' },
        ],
        validators: [
            {
                id: 'income_type-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'specialization',
        label: 'Tipo de actividad',
        type: 'select',
        col: 12,
        options: [
            { value: '', name: '' },
            { value: 'CONSTRUCTION_MANUFACTURING', name: 'Construcción/Manufactura' },
            { value: 'MILITARY', name: 'Militar/Defensa' },
            { value: 'HEALTH_CARE', name: 'Sanidad/Asistencia social/Farmacéutico' },
            { value: 'BANKING_INSURANCE', name: 'Banca/Seguros/Servicios financieros' },
            { value: 'EDUCATION', name: 'Educación' },
            { value: 'CIVIL_SERVICE', name: 'Público/Administración' },
            { value: 'RETAIL', name: 'Comercio' },
            { value: 'HOTEL_RESTAURANT_AND_LEISURE', name: 'Restauración/Hostelería' },
            { value: 'OTHER_OFFICE_BASED', name: 'Otros trabajos' },
        ],
        validators: [
            {
                id: 'specialization-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'monthly_income',
        label: 'Indica tus ingresos netos mensuales',
        type: 'text',
        value: '0',
        inputMode: 'decimal',
        col: 12,
        masked: {
            thousandSeparator: '.',
            decimalSeparator: ',',
            allowEmptyFormatting: true,
            suffix: ' €',
        },
        validators: [
            {
                id: 'monthly_income-maxlength',
                isValidFun: (expression) => checkMaxLength(expression, 8),
                alert: 'Introduce el valor correcto',
            },
            {
                id: 'monthly_income-minvalue',
                isValidFun: (expression) => checkMinValue(expression, 10),
                alert: 'Por favor, escribe un valor mayor o igual a 10',
            },
            {
                id: 'monthly_income-required',
                isValidFun: (expression) => checkIsFilledAmount(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'next_pay_date',
        label: 'Fecha en la que recibirás el salario',
        type: 'date',
        normalizeValFun: normalizeDate,
        col: 12,
        validators: [
            {
                id: 'next_pay_date-check',
                isValidFun: (expression) => checkNextPayDate(expression),
                alert: 'Campo obligatorio',
            },
            {
                id: 'next_pay_date-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'loan_purpose',
        label: 'Motivo del préstamo',
        type: 'select',
        col: 12,
        options: [
            { value: '', name: '' },
            { value: '0', name: 'Pagar otro prestamo' },
            { value: '1', name: 'Ayudar un amigo/familiar' },
            { value: '2', name: 'Gastos imprevistos' },
            { value: '3', name: 'Facturas/comida' },
            { value: '4', name: 'Compra inhabitual (ropa, regalo...)' },
            { value: '5', name: 'Vacaciones' },
        ],
        validators: [
            {
                id: 'loan_purpose',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'bank_account_number',
        label: 'Cuenta Bancaria',
        type: 'text',
        placeholder: 'ES__ ____ ____ ____ ____ ____',
        inputMode: 'decimal',
        col: 12,
        clipData: null,
        masked: {
            format: 'ES## #### #### #### #### ####',
            mask: '_',
        },
        validators: [
            {
                id: 'bank_account_number-min-length',
                isValidFun: (expression) => checkIsFilledBankAccount(expression),
                alert: 'Número de cuenta no válido',
            },
            {
                id: 'bank_account_number-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
];
