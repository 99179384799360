import React from 'react';

import { TextInput, EmailInput, CheckboxInput, TextareaInput } from '../components/FormFields';
import { SelectInput } from '../components/FormFields/SelectInput';
import { RadioInput } from '../components/FormFields/RadioInput';
import { DateInput } from '../components/FormFields/DateInput';
import { TelInput } from '../components/FormFields/TelInput';

const capitalize = (expression) => expression.charAt(0).toUpperCase() + expression.slice(1);

const Components = {
    TextInput,
    EmailInput,
    SelectInput,
    CheckboxInput,
    RadioInput,
    DateInput,
    TelInput,
    TextareaInput,
};

export const renderInput = (input, setInputs, valuePasted, inputFocused, calc) => {
    const Component = Components[capitalize(input.type) + 'Input'];

    return (
        <Component
            key={input.name}
            setInputs={setInputs}
            valuePasted={valuePasted}
            inputFocused={inputFocused}
            {...input}
            productId={calc}
        />
    );
};
