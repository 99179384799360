import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { navigate, graphql } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';

import { getCookie, setCookiesFromUrlParams } from '../helpers/setCookies';
import { renderInput } from '../helpers/renderInput';
import { useForm } from '../hooks/use-form';

import { HelmetBlock } from '../components/HelmetBlock';
import { Header } from '../components/HeaderBlock';
import { Progress } from '../components/ProgressBlock';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/index.css';

const Registration = ({ data }) => {
    const [stage, setStage] = useState(1);
    const [progress, setProgress] = useState(10);
    const [fields, setFields] = useState([]);
    const [pageScroll, setPageScroll] = useState(null);
    const isDebugMode = typeof window !== `undefined` && window.localStorage.getItem('debugMode') === 'on';

    useEffect(() => {
        if (pageScroll) {
            scrollTo(pageScroll);
        }
    }, [pageScroll]);

    useEffect(() => {
        setPageScroll(null);

        if (stage !== 1) {
            navigate('#top');
        }
    }, [stage]);

    const sendForm = () => {
        typeof window !== 'undefined' && window.gtag && window.gtag('event', 'Submit' + stage + 'Success');

        if (stage < 3) {
            setStage(stage + 1);
            setFields(fields.concat(inputs));
            setProgress(stage * 35);
        } else {
            const allFields = fields.concat(inputs);
            const optimisedAllFields = {};

            allFields.forEach((field) => {
                if (field.hidden) {
                    optimisedAllFields[field.name] = '';
                } else {
                    const fieldVal = field.normalizedValue ? field.normalizedValue : field.value;
                    if (field.type === 'date') {
                        const dateArr = fieldVal.split('-');
                        optimisedAllFields[field.name] = dateArr[2] + dateArr[1] + dateArr[0];
                    } else if (field.name === 'monthly_income') {
                        optimisedAllFields[field.name] = fieldVal.replace(/\.|\s€$/g, '');
                    } else if (field.name === 'bank_account_number') {
                        optimisedAllFields[field.name] = fieldVal.replace(/\s/g, '').slice(4);
                    } else {
                        optimisedAllFields[field.name] = fieldVal ? fieldVal : fieldVal === false ? false : '';
                    }
                }
            });

            optimisedAllFields['gclid'] = getCookie('gclid') ? getCookie('gclid') : '';
            optimisedAllFields['utm_medium'] = getCookie('utm_medium') ? getCookie('utm_medium') : '';

            if (isDebugMode) {
                console.debug('Model:', allFields);
                console.debug('Optimised fields:', optimisedAllFields);
            }

            navigate('/success-page', {
                state: {
                    fields: optimisedAllFields,
                },
            });
        }
    };

    useEffect(() => {
        //set get parameters to cookies
        setCookiesFromUrlParams();
    });

    const [inputs, setInputs, valuePasted, inputFocused, setSubmit, resetForm, message, setMessage] = useForm(
        stage,
        sendForm,
        setPageScroll
    );

    return (
        <>
            <HelmetBlock data={data.wpPage} />
            <Progress progress={progress} />
            <Header data={data.wpPage} />
            <StyledFormBlock>
                <Container>
                    <div className="form">
                        <h1 className="h1">Introduce tus datos básicos para analizar las ofertas</h1>
                        <form>
                            <div className="form__stage">
                                <b>{stage}</b> / 3
                            </div>
                            <div className="form-row">
                                {inputs.map((input) => renderInput(input, setInputs, valuePasted, inputFocused))}
                            </div>
                            <button type="submit" className={'btn btn-primary'} onClick={setSubmit}>
                                SIGUIENTE
                            </button>
                        </form>
                    </div>
                </Container>
            </StyledFormBlock>
        </>
    );
};

export default Registration;

const StyledFormBlock = styled.div`
    .container {
        max-width: 540px;
    }
    .h1 {
        margin: 10px 0;
        color: #378aff;
        font-size: 16px;
        font-weight: 700;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 28px;
        }
    }

    form {
        .form__stage {
            margin: 10px 0 30px;
            color: #378aff;
            font-size: 28px;
            text-align: center;
            @media (min-width: 768px) {
                margin: 10px 0 50px;
            }
        }

        .form-row {
            @media (min-width: 768px) {
                margin: 0 -25px;
            }
        }

        .form-group {
            margin-bottom: 20px;

            @media (min-width: 768px) {
                padding: 0 25px;
                margin-bottom: 50px;
            }
        }

        .select-wrapper + .error,
        .form-control + .error {
            font-size: 12px;
            color: red;

            @media (min-width: 768px) {
                font-size: 14px;
                position: absolute;
            }
        }

        label {
            color: #4c4c4c;
            font-size: 16px;
            font-weight: 700;

            @media (min-width: 768px) {
                font-size: 20px;
                font-weight: normal;
            }
        }

        input {
            font-size: 16px;
            padding: 9px 20px;
            line-height: 24px;
            height: auto;
            border: 1px solid #378aff;

            @media (min-width: 768px) {
                font-size: 18px;
            }

            &:focus {
                box-shadow: none;
            }

            &:invalid {
                box-shadow: none;
            }
        }

        .form-group_readonly {
            label {
                @media (min-width: 768px) {
                    margin-bottom: 0;
                }
            }

            input {
                color: #378aff;
                border: none;
                padding: 10px 20px;
                background: #f2f6fc;

                @media (min-width: 768px) {
                    width: 180px;
                }
            }
        }

        .form-control-income-wrapper {
            position: relative;

            &::before {
                content: '/ mensuales';
                position: absolute;
                right: 20px;
                color: #378aff;
                font-size: 16px;
                line-height: 44px;

                @media (min-width: 768px) {
                    font-size: 18px;
                }
            }

            input {
                padding-right: 130px;
            }

            + .error {
                font-size: 12px;
                color: red;

                @media (min-width: 768px) {
                    font-size: 14px;
                    position: absolute;
                }
            }
        }

        .custom-checkbox {
            .custom-control-label {
                font-weight: 400;

                &::before {
                    top: 4px;
                    width: 16px;
                    height: 16px;
                    border-radius: 0;
                    border: 1px solid #378aff;
                    @media (min-width: 768px) {
                        top: 6px;
                    }
                }

                &::after {
                    top: 2px;
                    left: -23px;
                    width: 20px;
                    height: 20px;
                    background: no-repeat top/contain;
                    @media (min-width: 768px) {
                        top: 4px;
                    }
                }

                a {
                    color: #378aff;
                    font-weight: 700;
                }
            }

            .custom-control-input {
                &:focus ~ .custom-control-label::before {
                    box-shadow: none;
                }

                &:checked ~ .custom-control-label::before {
                    background: transparent;
                    border-color: #378aff;
                }

                &:checked ~ .custom-control-label::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 16.2'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M16.3 4e-8l-8.92 8.92-3.69-3.69-3.65 3.65 7.33 7.33 12.7-12.7z'/%3E%3Cpath fill='%23378aff' fill-rule='evenodd' d='M7.234 11.3088L3.4792 7.554 2.2276 8.8056 7.234 13.812 17.962 3.084l-1.2516-1.2516z'/%3E%3C/svg%3E%0A");
                }
            }

            + .error {
                position: absolute;
                color: red;
                font-size: 12px;
                @media (min-width: 768px) {
                    font-size: 14px;
                }
            }
        }
        .custom-checkbox.out-form-body {
            .custom-control-label {
                font-size: 14px;
                color: #112a4f;

                &::before {
                    top: 2px;
                }

                &::after {
                    top: 0;
                }
            }
        }

        .btn {
            margin: 0 auto;
            display: block;
        }

        .btn-primary {
            background-color: #ff9d00;
            border-radius: 15px;
            border: none;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 2px;
            margin: 20px auto;
            padding: 11px 48px;
            text-transform: uppercase;
            width: 100%;

            @media (min-width: 768px) {
                width: auto;
            }
        }

        .btn-primary:hover,
        .btn-primary:focus {
            background-color: #e68d00;
        }

        .btn-primary:not(:disabled):not(.disabled):active {
            background-color: #ff9d00;
        }

        .btn-primary:not(:disabled):not(.disabled):active:focus {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    }
`;

export const pageQuery = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            id
            title
            databaseId
            parentDatabaseId
            seo {
                title
                metaDesc
            }
        }
    }
`;
