import { useEffect, useState } from 'react';
import { FORM } from '../settings';
import { registrationFirstStepModel } from '../models/registrationFirstStepModel';
import { registrationStepTwoModel } from '../models/registrationStepTwoModel';
import { registrationLastStepModel } from '../models/registrationLastStepModel';

export const useForm = (stage, submitCallback, setPageScroll) => {
    let initModel = {};
    let initProgress = 0;

    switch (stage) {
        case 1:
            initModel = registrationFirstStepModel;
            initProgress = 0;
            break;
        case 2:
            initModel = registrationStepTwoModel;
            initProgress = registrationFirstStepModel.length * 5;
            break;
        case 3:
            initModel = registrationLastStepModel;
            initProgress = (registrationFirstStepModel.length + registrationStepTwoModel.length) * 5;
            break;
        default:
            initModel = registrationFirstStepModel;
            initProgress = 0;
    }

    const [inputs, setInputs] = useState(initModel);
    const [message, setMessage] = useState({
        type: null,
        text: null,
    });

    useEffect(() => {
        setInputs(initModel);
    }, [initModel]);

    const resetForm = () => {
        inputs.forEach((i) => {
            i.value = '';
            i.alert = null;
        });
        setInputs([...inputs]);
    };

    const handleChange = (e) => {
        e.persist();
        inputs.forEach((i) => {
            if (i.name === e.target.name) {
                i.value = i.type === 'checkbox' ? e.target.checked : e.target.value;
                parseInput(i);
                normalizeValue(i);
                i.dependent_field && checkDependentFieldsSate(i);
                validateInput(i);
            }
        });
        setInputs([...inputs]);

        inputs.forEach((i) => validateInputInfo(i));
        if (inputs.some((i) => i.hiddenAlert)) {
            setMessage({
                type: 'error',
                text: FORM.FORM_INCORRECT,
            });
        } else {
            setMessage({
                type: 'success',
                text: FORM.FORM_SUCCESS,
            });
        }
    };

    const valuePasted = (name, value) => {
        inputs.forEach((i) => {
            if (i.name === name) {
                i.value = value;
                parseInput(i);
                normalizeValue(i);
                i.dependent_field && checkDependentFieldsSate(i);
                validateInput(i);
            }
        });

        setInputs([...inputs]);
    };

    const inputFocused = (name, value) => {
        inputs.forEach((i) => {
            if (i.name === name) {
                i.value = value;
                parseInput(i);
                normalizeValue(i);
                i.dependent_field && checkDependentFieldsSate(i);
            }
        });

        setInputs([...inputs]);
    };

    const checkDependentFieldsSate = (input) => {
        const value = input.value;
        const dependentFieldName = input.dependent_field.name;
        const dependentField = inputs.find((field) => field.name === dependentFieldName);

        if (value === input.dependent_field.waiting_for_value) {
            dependentField.hidden = false;
        } else {
            dependentField.hidden = true;
        }
    };

    const handleSubmit = (e) => {
        e && e.preventDefault();

        setPageScroll(null);

        inputs.forEach((i) => validateInput(i));
        if (inputs.some((i) => i.alert)) {
            setInputs([...inputs]);

            const firstError = inputs.find((i) => i.alert);
            setPageScroll('#' + firstError.name + '-form-group');

            setMessage({
                type: 'error',
                text: FORM.FORM_INCORRECT,
            });
        } else {
            setMessage({
                type: 'success',
                text: FORM.FORM_SUCCESS,
            });
            submitCallback();
        }
    };

    const parseInput = (input) => (input.value = input.parseFun ? input.parseFun(input.value) : input.value);

    const normalizeValue = (input) =>
        (input.normalizedValue = input.normalizeValFun ? input.normalizeValFun(input.value) : '');

    const validateInput = (input) => {
        let alert = null;
        !input.hidden &&
            input.validators &&
            input.validators.forEach((v) => (alert = v.isValidFun && !v.isValidFun(input.value) ? v.alert : alert));
        input.alert = alert;
    };

    const validateInputInfo = (input) => {
        let alert = null;
        input.validators &&
            input.validators.forEach((v) => (alert = v.isValidFun && !v.isValidFun(input.value) ? v.alert : alert));
        input.hiddenAlert = alert;
    };

    return [inputs, handleChange, valuePasted, inputFocused, handleSubmit, resetForm, message, setMessage];
};
