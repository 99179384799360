import React from 'react';
import { Col } from 'react-bootstrap';
import { isIE } from 'react-device-detect';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { ValidationAlert } from './ValidationAlert';

export const DateInput = ({ name, label, type, value, alert, hiddenAlert, setInputs, col, position, productId }) => {
    let classes = 'form-control';
    if (alert) {
        classes += ' error';
    }
    const dateInputWrapperProps = {
        md: col,
        className: 'form-group',
        id: name + '-form-group',
    };
    const dateInputProps = {
        id: name,
        name: name,
        value: value || '',
        className: classes,
        onChange: setInputs,
    };

    if (isIE) {
        if (dateInputProps.value) {
            const dateArr = dateInputProps.value.split('-');
            dateInputProps.value = dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0]; // DD / MM / YYYY
        }

        return (
            <StyledDateInput {...dateInputWrapperProps}>
                {label && <label htmlFor={name}>{label}</label>}
                <NumberFormat
                    {...dateInputProps}
                    type="text"
                    inputMode="numeric"
                    allowEmptyFormatting={true}
                    displayType={'input'}
                    format="## / ## / ####"
                    mask={['d', 'd', 'm', 'm', 'y', 'y', 'y', 'y']}
                />
                <ValidationAlert content={alert} />
            </StyledDateInput>
        );
    } else {
        return (
            <StyledDateInput {...dateInputWrapperProps}>
                {label && <label htmlFor={name}>{label}</label>}
                <input {...dateInputProps} type={type} />
                <ValidationAlert content={alert} />
            </StyledDateInput>
        );
    }
};

const StyledDateInput = styled(Col)`
    .form-control {
        height: 44px; // Fix collapsed height on iOS, if field is empty
    }
`;
