import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { ValidationAlert } from './ValidationAlert';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export const TextareaInput = ({
    name,
    label,
    value,
    placeholder,
    alert,
    hiddenAlert,
    setInputs,
    col,
    position,
    productId,
}) => {
    return (
        <Col md={col} id={name + '-form-group'}>
            <div className="input textarea">
                <label htmlFor={name}>{label}</label>
                <textarea
                    id={name}
                    name={name}
                    placeholder={placeholder || ''}
                    value={value || ''}
                    onChange={setInputs}
                    className={alert ? 'error' : ''}
                />
                <ValidationAlert content={alert} />
            </div>
        </Col>
    );
};

export const TextInput = ({
    name,
    label,
    type,
    lang,
    value,
    placeholder,
    inputMode,
    alert,
    hidden,
    hiddenAlert,
    setInputs,
    valuePasted,
    inputFocused,
    col,
    position,
    productId,
    masked,
    readonly,
    clipData,
    inline,
    maxLength,
}) => {
    const formData = useSelector((state) => state.form);
    const inputRef = useRef(null);
    const inputVal = formData[name];

    let classes = 'form-control';
    if (alert) {
        classes += ' error';
    }

    if (name === 'amount' || name === 'term') {
        value = inputVal;
        typeof window !== 'undefined' && localStorage.setItem(name, inputVal);
    }

    const onPaste = (e) => {
        if (name === 'bank_account_number') {
            clipData = e.clipboardData.getData('text').replace(/\s/g, '').replace('ES', '');

            if (clipData && clipData.length >= 20) {
                e.preventDefault();
                if (clipData && clipData.length > 22) {
                    clipData = clipData.slice(0, 22);
                }
                clipData = 'ES' + clipData;
                clipData = clipData.match(/.{2,4}/g).join(' ');
                valuePasted(name, clipData);
            }
        }
        if (name === 'cell_phone') {
            e.preventDefault();
            clipData = e.clipboardData.getData('text').replace(/\s/g, '');
            if (clipData) {
                if (clipData && clipData.length > maxLength) {
                    clipData = clipData.slice(0, maxLength);
                }
                valuePasted(name, clipData);
            }
        }
    };

    const onFocus = () => {
        if (name === 'monthly_income' && inputRef.current.value === '0 €') {
            inputFocused(name, '');
        }
    };

    return !hidden ? (
        <Col
            md={col}
            className={
                readonly
                    ? inline
                        ? 'form-group form-group_readonly d-md-flex align-items-md-center justify-content-md-between'
                        : 'form-group form-group_readonly'
                    : inline
                    ? 'form-group d-md-flex align-items-md-center justify-content-md-between'
                    : 'form-group'
            }
            id={name + '-form-group'}
        >
            <label htmlFor={name}>{label}</label>
            {masked ? (
                <ConditionalWrapper
                    condition={name === 'monthly_income'}
                    wrapper={(children) => <div className={'form-control-income-wrapper'}>{children}</div>}
                >
                    <NumberFormat
                        thousandSeparator={masked.thousandSeparator}
                        decimalSeparator={masked.decimalSeparator}
                        allowEmptyFormatting={masked.allowEmptyFormatting}
                        suffix={masked.suffix}
                        displayType={'input'}
                        id={name}
                        name={name}
                        type={type}
                        lang={lang || 'es'}
                        value={value || ''}
                        placeholder={placeholder || ''}
                        inputMode={inputMode || 'text'}
                        readOnly={readonly || false}
                        className={classes}
                        onChange={setInputs}
                        onFocus={onFocus}
                        onPaste={onPaste}
                        getInputRef={inputRef}
                        maxLength={maxLength}
                        format={masked.format || null}
                        mask={masked.mask || null}
                    />
                </ConditionalWrapper>
            ) : (
                <input
                    id={name}
                    name={name}
                    type={type}
                    lang={lang || 'es'}
                    value={value || ''}
                    placeholder={placeholder || ''}
                    inputMode={inputMode || 'text'}
                    className={classes}
                    onChange={setInputs}
                    onFocus={onFocus}
                    onPaste={onPaste}
                    maxLength={maxLength}
                />
            )}
            <ValidationAlert content={alert} />
        </Col>
    ) : null;
};

export const EmailInput = ({
    name,
    label,
    type,
    value,
    placeholder,
    alert,
    hiddenAlert,
    setInputs,
    col,
    position,
    productId,
    readonly,
    inline,
    maxLength,
}) => {
    let classes = 'form-control';
    if (alert) {
        classes += ' error';
    }

    return (
        <Col
            md={col}
            className={
                readonly
                    ? inline
                        ? 'form-group form-group_readonly d-md-flex align-items-md-center justify-content-md-between'
                        : 'form-group form-group_readonly'
                    : inline
                    ? 'form-group d-md-flex align-items-md-center justify-content-md-between'
                    : 'form-group'
            }
            id={name + '-form-group'}
        >
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                name={name}
                type={type}
                value={value || ''}
                placeholder={placeholder || ''}
                className={classes}
                onChange={setInputs}
                maxLength={maxLength}
            />
            <ValidationAlert content={alert} />
        </Col>
    );
};

export const CheckboxInput = ({
    name,
    label,
    type,
    value,
    alert,
    hiddenAlert,
    setInputs,
    col,
    position,
    productId,
}) => {
    let classes = 'custom-control-input';
    if (alert) {
        classes += ' error';
    }

    const fieldWrapperProps = {
        className: 'custom-control custom-checkbox',
    };

    if (position === 'in-form-body') {
        fieldWrapperProps.className += ` in-form-body`;
    }

    if (position !== 'in-form-body') {
        fieldWrapperProps.className += ' out-form-body';
    }

    return (
        <Col md={col} className="form-group" id={name + '-form-group'}>
            <div {...fieldWrapperProps}>
                <input
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    checked={value || false}
                    onChange={setInputs}
                    className={classes}
                />
                <label htmlFor={name} className={'custom-control-label'} dangerouslySetInnerHTML={{ __html: label }} />
            </div>
            <ValidationAlert content={alert} />
        </Col>
    );
};
