import { normalizeDate } from '../helpers/valueNormalizer';
import { parseOnlyNumbers } from '../helpers/inputParser';
import {
    checkIsFilled,
    checkMinLength,
    checkMaxLength,
    checkIsText,
    checkAge,
    checkIsDniOrNie,
    checkEmailPattern,
    checkPhonePattern,
    checkIsTrue,
} from '../helpers/inputValidator';

export const registrationFirstStepModel = [
    {
        name: 'amount',
        label: '¿Cuánto necesitas?',
        type: 'text',
        col: 12,
        masked: {
            thousandSeparator: '.',
            decimalSeparator: ',',
            suffix: ' €',
        },
        readonly: true,
        inline: true,
        value: '300',
    },
    {
        name: 'term',
        label: '¿Para cuánto tiempo?',
        type: 'text',
        col: 12,
        masked: {
            suffix: ' días',
        },
        readonly: true,
        inline: true,
        value: '30',
    },
    {
        name: 'first_name',
        label: 'Nombre',
        type: 'text',
        col: 12,
        validators: [
            {
                id: 'first_name-min-length',
                isValidFun: (expression) => checkMinLength(expression, 2),
                alert: 'Nombre incorrecto o falta',
            },
            {
                id: 'first_name-max-length',
                isValidFun: (expression) => checkMaxLength(expression, 100),
                alert: 'El valor no puede ser más de 100 los símbolos',
            },
            {
                id: 'first_name-text',
                isValidFun: (expression) => checkIsText(expression),
                alert: 'Este campo solo puede contener letras del alfabeto latino en mayúsculas y minúsculas',
            },
            {
                id: 'first_name-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'last_name',
        label: 'Primer apellido',
        type: 'text',
        col: 12,
        validators: [
            {
                id: 'last_name-min-length',
                isValidFun: (expression) => checkMinLength(expression, 2),
                alert: 'Primer apellido incorrecto o falta',
            },
            {
                id: 'last_name-max-length',
                isValidFun: (expression) => checkMaxLength(expression, 100),
                alert: 'El valor no puede ser más de 100 los símbolos',
            },
            {
                id: 'last_name-text',
                isValidFun: (expression) => checkIsText(expression),
                alert: 'Este campo solo puede contener letras del alfabeto latino en mayúsculas y minúsculas',
            },
            {
                id: 'last_name-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'last_name_2',
        label: 'Segundo apellido',
        type: 'text',
        hidden: false,
        col: 12,
        validators: [
            {
                id: 'last_name_2-min-length',
                isValidFun: (expression) => checkMinLength(expression, 2),
                alert: 'Segundo apellido incorrecto o falta',
            },
            {
                id: 'last_name_2-max-length',
                isValidFun: (expression) => checkMaxLength(expression, 100),
                alert: 'El valor no puede ser más de 100 los símbolos',
            },
            {
                id: 'last_name_2-text',
                isValidFun: (expression) => checkIsText(expression),
                alert: 'Este campo solo puede contener letras del alfabeto latino en mayúsculas y minúsculas',
            },
            {
                id: 'last_name_2-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'no_last_name_2',
        label: 'Marcar si no tengo segundo apellido',
        value: false,
        col: 12,
        position: 'in-form-body',
        type: 'checkbox',
        dependent_field: {
            name: 'last_name_2',
            waiting_for_value: false,
        },
    },
    {
        name: 'gender',
        label: 'Género',
        type: 'radio',
        col: 12,
        options: [
            { value: 'M', name: 'Hombre' },
            { value: 'F', name: 'Mujer' },
        ],
        validators: [
            {
                id: 'gender-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Tienes que añadir tu género',
            },
        ],
    },
    {
        name: 'birth_date',
        label: 'Fecha de nacimiento',
        type: 'date',
        value: '1980-01-01', // YYYY-MM-DD
        normalizeValFun: normalizeDate,
        col: 12,
        validators: [
            {
                id: 'birth_date-age',
                isValidFun: (expression) => checkAge(expression),
                alert: 'Fecha no válida',
            },
            {
                id: 'birth_date-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'identity_card_number',
        label: 'Número de DNI o NIE',
        type: 'text',
        maxLength: 9,
        col: 12,
        validators: [
            {
                id: 'identity_card_number-dni-or-nie',
                isValidFun: (expression) => checkIsDniOrNie(expression),
                alert: 'Número inválido',
            },
            {
                id: 'identity_card_number-required',
                isValidFun: (expression) => checkIsFilled(expression),
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'email',
        label: 'Correo electrónico',
        type: 'email',
        maxLength: 120,
        col: 12,
        validators: [
            {
                id: 'email-pattern',
                isValidFun: checkEmailPattern,
                alert: 'Debes introducir una dirección de correo electrónico válida',
            },
            {
                id: 'email-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'cell_phone',
        label: 'Télefono móvil',
        type: 'text',
        maxLength: 9,
        inputMode: 'decimal',
        parseFun: parseOnlyNumbers,
        col: 12,
        validators: [
            {
                id: 'cell_phone-min-length',
                isValidFun: (expression) => checkMinLength(expression, 9),
                alert: 'Teléfono móvil incorrecto o falta',
            },
            {
                id: 'cell_phone-pattern',
                isValidFun: (expression) => checkPhonePattern(expression),
                alert: 'Teléfono móvil incorrecto o falta',
            },
            {
                id: 'cell_phone-required',
                isValidFun: checkIsFilled,
                alert: 'Campo obligatorio',
            },
        ],
    },
    {
        name: 'policy',
        label: 'He leído y acepto la <a target="_blank" href="/politica-privacidad">Política de Privacidad</a>',
        type: 'checkbox',
        value: false,
        col: 12,
        validators: [
            {
                id: 'policy-required',
                isValidFun: checkIsTrue,
                alert: 'Tienes que aceptar la política de privacidad',
            },
        ],
    },
];
